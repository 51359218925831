document.addEventListener('DOMContentLoaded', function () {
  /* Mobile menu */
  var menu_button = document.querySelector('.menu_button--open')
  var menu_button_close = document.querySelector('.menu_button--close')
  var menu = document.querySelector('.block-menu')

  // open
  if (menu_button !== null) {
    menu_button.addEventListener('click', function () {
      menu.classList.add('block-menu--open')
      localStorage.setItem('scroll_pos', document.body.scrollTop)
      document.body.classList.add('noscroll')
    })
  }

  // close
  if (menu_button_close !== null) {
    menu_button_close.addEventListener('click', function () {
      menu.classList.remove('block-menu--open')
      menu.classList.remove('init')
      document.body.classList.remove('noscroll')
      var scroll_pos = localStorage.getItem('scroll_pos')
      if (scroll_pos !== null) {
        document.body.scrollTop = scroll_pos
      }
    })
  }

  // sidemenu
  var sidemenu_button = document.querySelector('.sidemenu-button--mobile')
  var sidemenu = document.querySelector('.sidemenu__list')
  if (sidemenu_button) {
    sidemenu_button.addEventListener('click', function () {
      sidemenu.classList.toggle('sidemenu__list--show')
      sidemenu_button.classList.toggle('open')
    })
  }

  // search
  var search_button = document.querySelector('.search_button--open')
  var search_button_close = document.querySelector('.search_button--close')
  var search = document.querySelector('.search-form--mobile')
  var logo = document.querySelector('.block-header .logo')
  if (search_button !== null) {
    search_button.addEventListener('click', function () {
      search.classList.add('search-form--mobile--open')
      logo.classList.add('logo--hide')
    })
  }
  if (search_button_close !== null) {
    search_button_close.addEventListener('click', function () {
      search.classList.remove('search-form--mobile--open')
      search.classList.remove('init')
      logo.classList.remove('logo--hide')
      logo.classList.remove('init')
    })
  }
  // Searchbox precheck
  var emptyCheck = function (event) {
    if (this.query.value == '') {
      event.preventDefault()
    }
  }
  var forms = document.querySelectorAll('form[class*="search-"]')
  for (var i = 0; i < forms.length; ++i) {
    forms[i].addEventListener('submit', emptyCheck, true)
  }

  /* Prevent "before-forward" browser cache, to not get back to an open menu state on mobile */
  var open_mobile_menu = document.querySelector('.block-menu--open')
  window.addEventListener('pageshow', function (event) {
    if (event.persisted && open_mobile_menu !== null) {
      open_mobile_menu.dispatchEvent('click')
    }
  })

  // open external links in new window
  var links = document.querySelectorAll(
    'a[href^="http://"]:not([href^="' +
      location.hostname +
      '"]), a[href^="https://"]:not([href^="' +
      location.hostname +
      '"])'
  )
  for (var i = 0; i < links.length; ++i) {
    var link = links[i]
    links[i].setAttribute('target', '_blank')
  }

  // Smooth scrollto
  var scrollTo = function (element, to, duration, callback) {
    if (duration <= 0) return
    var difference = to - element.scrollTop
    var perTick = (difference / duration) * 10

    if (callback) {
      unfold(duration)
    }

    setTimeout(function () {
      element.scrollTop = element.scrollTop + perTick
      if (element.scrollTop === to) {
        return
      }
      scrollTo(element, to, duration - 10)
    }, 10)
  }

  var ajaxify = function (forms) {
    for (var i = 0; i < forms.length; i++) {
      var uuid = forms[i].id
      var form = forms[i]
      var thank_you = document.querySelector('#' + uuid + ' + .thank_you')

      var request = new XMLHttpRequest()
      window.addReadyStateChangeListener(request, form, thank_you)

      window.addAjaxSubmitListener(request, form)
    }
  }

  var question_forms = document.querySelectorAll('.question-form')
  ajaxify(question_forms)
  var subscription_forms = document.querySelectorAll('.subscription-form')
  ajaxify(subscription_forms)
  // var referral_forms = document.querySelectorAll(".block-subscription_action .referral-form"); /*do not select old referral form*/
  // ajaxify(referral_forms);
  var frontoffice_forms = document.querySelectorAll('.frontoffice-form')
  ajaxify(frontoffice_forms)
  var toegang_forms = document.querySelectorAll('.toegang-form')
  ajaxify(toegang_forms)

  // Question form
  var question_form_show_trigger = document.querySelectorAll(
    '.question_action_button'
  )
  if (question_form_show_trigger.length) {
    for (var i = 0; i < question_form_show_trigger.length; i++) {
      question_form_show_trigger[i].addEventListener('click', function () {
        // hide the button
        this.classList.add('hide')
        // show the form
        var question_form =
          this.parentNode.parentNode.parentNode.querySelector('.question_form')
        question_form.classList.remove('hide')
      })
    }

    // check if action button needs to be hidden (form is not clean)
    var unclean_question_form = document.querySelectorAll(
      '.question_form:not(.hide)'
    )
    if (unclean_question_form.length) {
      for (var i = 0; i < unclean_question_form.length; i++) {
        unclean_question_form[i].parentNode
          .querySelector('.question_action_button')
          .classList.add('hide')
      }
    }
  }

  /* Initialization of FAQ block max-sizes */
  var init_FAQ = function (onload) {
    var faq_items = document.querySelectorAll('.faq_item')

    for (var j = 0; j < faq_items.length; j++) {
      var question_side = faq_items[j].querySelector('.question_side')
      var answer_side = faq_items[j].querySelector('.answer_side')

      /* Get max size of everything (overwrite CSS) */
      question_side.style.alignSelf = 'flex-start'
      faq_items[j].style.maxHeight = ''
      question_side.style.maxHeight = ''
      answer_side.style.maxHeight = ''
      faq_items[j].style.transition = 'none'
      question_side.style.transition = 'none'
      answer_side.style.transition = 'none'

      var question_height = question_side.offsetHeight
      var answer_height = answer_side.offsetHeight

      /* Init hidden questions only the first time */
      if (onload === true) {
        var item_index = parseInt(faq_items[j].getAttribute('data-question'))
        if (item_index < 4) {
          faq_items[j].style.maxHeight = answer_height
        } else {
          faq_items[j].style.maxHeight = 0
        }
      }

      /* Set possible sizes for answer in data attributes */
      answer_side.setAttribute('data-original-height', question_height + 'px')
      answer_side.setAttribute('data-max-height', answer_height + 'px')
      /* Force max-height depending on the state of the faq_item*/
      if (faq_items[j].className.indexOf('active') > -1) {
        answer_side.style.maxHeight = answer_height + 'px'
      } else {
        answer_side.style.maxHeight = question_height + 'px'
      }

      /* Set back CSS */
      faq_items[j].style.transition = ''
      question_side.style.transition = ''
      answer_side.style.transition = ''
      question_side.style.alignSelf = 'stretch'
    }
  }

  window.addEventListener(
    'resize',
    init_FAQ
  ) /* for tablet portrait/landscape switch */
  init_FAQ(true)
})

/* AJAX validation/submission */
window.addReadyStateChangeListener = function (request, form, thank_you) {
  request.onreadystatechange = function () {
    if (request.readyState === 4) {
      if (request.status === 200) {
        var response = JSON.parse(request.responseText)
        if (response.status === 400) {
          /* form is invalid */
          var errors = response.errors
          var old_errors = form.querySelectorAll('.form__error')
          if (old_errors.length) {
            old_errors.forEach(function (item) {
              item.parentNode.removeChild(item)
            })
          }
          for (var key in errors) {
            if (errors.hasOwnProperty(key)) {
              var field_errors = errors[key]
              for (var i = 0; i < field_errors.length; i++) {
                var error_msg = document.createElement('P')
                error_msg.className = 'form__error'
                error_msg.innerText = field_errors[i]

                form
                  .querySelector('.form__field--' + key)
                  .appendChild(error_msg)
              }
            }
          }
        } else if (response.status === 200) {
          /* form is valid, thank you */
          // scroll to thank you message
          var menu_height = document.querySelector('.block-header').offsetHeight
          var toPos =
            form.parentNode.parentNode.parentNode.getBoundingClientRect().top -
            menu_height
          var curScroll =
            document.documentElement.scrollTop || document.body.scrollTop
          var to = curScroll + toPos
          scrollTo(document.documentElement, to, 500)
          scrollTo(document.body, to, 500)
          setTimeout(function () {
            form.classList.add('hide')
            thank_you.classList.remove('hide')
          }, 500)
        }
      } else {
        console.log(
          'An error occurred during your request: ' +
            request.status +
            ' ' +
            request.statusText
        )
      }
    }
  }
}

window.addAjaxSubmitListener = function (request, form) {
  form.addEventListener('submit', function (e) {
    e.preventDefault()

    var formData = new FormData(this)

    request.open('POST', this.action, true)
    request.setRequestHeader(
      'X-Requested-With',
      'XMLHttpRequest'
    ) /*tell django this is ajax*/
    request.send(formData)
  })
}
